import React, { createContext, useReducer } from 'react';

const FiltersContext = createContext();

const getInitialState = () => {
  return {
    currentEvent: {},
    currentSearch: '',
    currentBranch: null,
    workersScroll: 0,
    currentRegion: 0,
    pageMeta: {},
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setCurrentEvent':
      return {
        ...state,
        currentEvent: action.event,
      };
    case 'setCurrentSearch':
      return {
        ...state,
        currentSearch: action.term,
      };
    case 'setCurrentBranch':
      return {
        ...state,
        currentBranch: action.branch,
      };
    case 'setWorkersPosition':
      return {
        ...state,
        workersScroll: action.position,
      };
    case 'setCurrentRegion':
      return {
        ...state,
        currentRegion: action.region,
      };
    case 'setPageMeta':
      return {
        ...state,
        pageMeta: action.pageMeta,
      };
    default:
      return state;
  }
};

function FiltersProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const setCurrentEvent = (event) => {
    dispatch({ type: 'setCurrentEvent', event });
  };

  const setCurrentSearch = (term) => {
    dispatch({ type: 'setCurrentSearch', term });
  };

  const setCurrentBranch = (branch) => {
    dispatch({ type: 'setCurrentBranch', branch });
  };

  const setWorkersScroll = (position) => {
    dispatch({ type: 'setWorkersPosition', position });
  };

  const setCurrentRegion = (region) => {
    dispatch({ type: 'setCurrentRegion', region });
  };

  const setPageMeta = (meta) => {
    let pageMeta = { ...state.pageMeta };

    meta.forEach((item) => {
      const { name, page, term, scroll } = item;
      pageMeta[name] = { page, term, scroll };
    });

    dispatch({ type: 'setPageMeta', pageMeta });
  };

  return (
    <FiltersContext.Provider
      value={{
        setCurrentEvent,
        setCurrentSearch,
        setCurrentBranch,
        setWorkersScroll,
        setCurrentRegion,
        setPageMeta,
        currentBranch: state.currentBranch,
        currentEvent: state.currentEvent,
        currentSearch: state.currentSearch,
        workersScroll: state.workersScroll,
        currentRegion: state.currentRegion,
        pageMeta: state.pageMeta,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export { FiltersProvider, FiltersContext };
