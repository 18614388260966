import * as React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }) {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default GuestGuard;
