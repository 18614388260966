import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { gql, useQuery } from '@apollo/client';
import { X as Close, MoreVertical } from 'react-feather';

import {
  Card as MuiCard,
  CardHeader,
  Chip as MuiChip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { spacing } from '@mui/system';

import useAuth from '../../../hooks/useAuth';
import useFilters from '../../../hooks/useFilters';
import { tConvert } from '../../../utils/utils';
import { ButtonDropdown, Search } from '../../../utils/components';

const GET_EVENT_BRANCH_ATTENDANCE = gql`
  query Event($id: ID!, $branchId: Int) {
    event(id: $id, idType: DATABASE_ID) {
      id: databaseId
      workersAttendance: attendances(
        first: 0
        where: { branchId: $branchId, orderby: { field: ID, order: ASC } }
      ) {
        nodes {
          ID: databaseId
          workerName
          branchId
          branchName
          createdAt
          workerGroups {
            nodes {
              groupLevel
            }
          }
        }
      }
    }
  }
`;

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 14px;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary'].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const FilterContainer = styled.div`
  display: flex;
  padding: 0 16px;
`;

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.body} {
    font-size: 0.89rem;
  }
`;

const CustomTableCellName = styled(CustomTableCell)`
  font-weight: 500;
`;

const Percent = styled.span`
  color: ${(props) => props.theme.palette[props.color].light};
`;

const Paper = styled(MuiPaper)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: 'name', alignment: 'left', label: 'Name' },
  { id: 'branchPastor', alignment: 'left', label: 'Pastor' },
  { id: 'workersPresent', alignment: 'left', label: 'Workers Present' },
  { id: 'workersTotal', alignment: 'left', label: 'Total Workers' },
  { id: 'workersPercent', alignment: 'left', label: 'Percent' },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function BranchDialog({ open, handleClose, branch, currentEventId, endTime }) {
  const { progress } = useAuth();
  const { loading, data } = useQuery(GET_EVENT_BRANCH_ATTENDANCE, {
    variables: { id: currentEventId, branchId: branch.ID },
    ...(!endTime && { fetchPolicy: 'network-only' }),
  });

  if (loading || !data) {
    return progress;
  }

  const workersAttendance = data.event.workersAttendance;
  const rows = workersAttendance.nodes.map((item) => {
    const { workerGroups, createdAt, ...rest } = item;
    const workerLevel = workerGroups.nodes
      .slice(0)
      .reduce((acc, item, i, arr) => {
        if (i === 1) arr.splice(1);
        let level = item.groupLevel;
        if (i === 1) {
          level = `${level.substring(0, 5)}...`;
        }
        return `${acc ? `${acc} |` : ''} ${level}`;
      }, '');
    const time = new Date(createdAt);
    return {
      ...rest,
      workerLevel,
      createdAt,
      cameBy: tConvert(time),
    };
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle id="alert-dialog-title">
        {`${branch?.name} Attendance`}
      </DialogTitle>
      <DialogContent>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Worker Level</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.ID}>
                <TableCell>{index + 1}</TableCell>
                <CustomTableCellName>{row.workerName}</CustomTableCellName>
                <CustomTableCell>{row.workerLevel}</CustomTableCell>
                <CustomTableCell>{row.cameBy}</CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

function BranchTable({
  workersAttendance,
  dataBranch,
  pastorsAttendance,
  currentEventId,
  includeAutonomous,
  endTime,
  role,
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(role === 'author' ? 200 : 10);
  const [currentBranchId, setBranchId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPastors, setCurrentPastors] = useState('');
  const { currentRegion, setCurrentRegion } = useFilters();

  useEffect(() => {
    setPage(0);
  }, [searchTerm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const regionIds = includeAutonomous ? [4, 5] : [5];
  const regionOptions = dataBranch
    .filter((item) => regionIds.includes(item.typeId))
    .map((item) => {
      return {
        value: item.ID,
        label: `${item.name} ${
          item.typeId === 4 ? 'Autonomous Region' : 'Region'
        }`,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const mergedData = dataBranch.map((bItem) => ({
    ...bItem,
    ...workersAttendance.pageInfo.branchData.find(
      (dItem) => dItem.ID === bItem.ID
    ),
  }));
  const pastorIds = pastorsAttendance.nodes.map((item) => item.workerId);
  let rows = mergedData.map((item) => {
    return {
      id: item.ID,
      name: item.name,
      branchPastor:
        item.pastor && item.ID !== 1
          ? `${item.pastor?.firstName} ${item.pastor?.lastName}`
          : '-',
      pastorStatus: pastorIds.includes(item.pastorId) ? 'present' : 'absent',
      workersPresent: item.present,
      workersPercent: Math.floor((item.present * 100) / item.total),
      workersTotal: item.total,
      regionId: item.regionId,
      typeId: item.typeId,
    };
  });

  if (searchTerm) {
    rows = rows.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  if (currentPastors) {
    rows = rows.filter((item) => item.pastorStatus === currentPastors);
  }

  if (currentRegion) {
    rows = rows.filter((item) => item.regionId === currentRegion);
  }

  if (!includeAutonomous) {
    rows = rows.filter((item) => ![4, 7].includes(item.typeId));
  }

  const handleCloseModal = () => setBranchId(null);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const incompleteBranchIds = [1, 202, 203, 204, 208];

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Overview Branches"
      />
      <Paper>
        <FilterContainer>
          <ButtonDropdown
            options={[
              { value: 'present', label: 'Pastors Present' },
              { value: 'absent', label: 'Pastors Absent' },
            ]}
            handleChange={setCurrentPastors}
            value={currentPastors}
            allLabel={'All Pastors'}
          />
          <ButtonDropdown
            options={regionOptions}
            handleChange={setCurrentRegion}
            value={currentRegion}
            allLabel={'All Regions'}
          />
          <Search
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search Branch"
          />
        </FilterContainer>
        <TableContainer>
          <Table size={'medium'}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={row.id}
                      onClick={() => {
                        if (row.id !== 1) {
                          setBranchId(row.id);
                        }
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <CustomTableCellName>
                        {row.name}{' '}
                        {[4, 7].includes(row.typeId) && (
                          <small> - Autonomous</small>
                        )}
                      </CustomTableCellName>
                      <TableCell>
                        {row.pastorStatus === 'present' ? (
                          <Chip label={row.branchPastor} color="success" />
                        ) : (
                          <Chip label={row.branchPastor} color="warning" />
                        )}
                      </TableCell>
                      <CustomTableCell>{row.workersPresent}</CustomTableCell>
                      <CustomTableCell>
                        {row.workersTotal}
                        {incompleteBranchIds.includes(row.id) && (
                          <span style={{ color: 'red' }}> *</span>
                        )}
                      </CustomTableCell>
                      <CustomTableCell>
                        <Percent
                          color={row.workersPercent >= 50 ? 'success' : 'error'}
                        >{`${row.workersPercent}%`}</Percent>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {currentBranchId && (
        <BranchDialog
          open={currentBranchId ? true : false}
          handleClose={handleCloseModal}
          branch={dataBranch.find((item) => item.ID === currentBranchId)}
          currentEventId={currentEventId}
          endTime={endTime}
        />
      )}
    </Card>
  );
}

export default BranchTable;
