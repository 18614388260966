import React from 'react';
import styled from 'styled-components/macro';

import { Grid, Avatar, Typography } from '@mui/material';

import useAuth from '../../hooks/useAuth';

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooter = ({ ...rest }) => {
  const { user } = useAuth();

  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item>
          {!!user && <Avatar alt={user.name} src={user.avatar.url} />}
        </Grid>
        <Grid item>
          {!!user && <FooterText variant="body2">{user.name}</FooterText>}
          <FooterSubText variant="caption" noWrap sx={{ width: '170px' }}>
            {user.email}
          </FooterSubText>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
