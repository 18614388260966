import { Home, Sliders, Settings, Users } from 'react-feather';

const pagesSection = [
  {
    href: '/dashboard',
    icon: Sliders,
    title: 'Dashboard',
  },
  {
    href: '/workers',
    icon: Users,
    title: 'Workers',
  },
  {
    href: '/branches',
    icon: Home,
    title: 'Branches',
  },
  {
    href: '/settings',
    icon: Settings,
    title: 'Settings',
  },
];

const navItems = [
  {
    title: 'pages',
    pages: pagesSection,
  },
];

export default navItems;
