import React, { createContext, useReducer } from 'react';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import TopBarProgress from 'react-topbar-progress-indicator';

const AuthContext = createContext();

const uri = 'https://workforce.tlccrm.org/graphql';

const userKey = 'tlc-workforce-user';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri,
});

const loginMutation = gql`
  mutation LoginUser($userName: String!, $password: String!) {
    login(
      input: {
        clientMutationId: "login"
        username: $userName
        password: $password
      }
    ) {
      user {
        id: databaseId
        name
        email
        token: jwtAuthToken
        refreshToken: jwtRefreshToken
        avatar {
          url
        }
        roles {
          nodes {
            name
          }
        }
      }
    }
  }
`;

const passwordResetEmailMutation = gql`
  mutation PasswordResetEmail($email: String!) {
    sendPasswordResetEmail(
      input: { clientMutationId: "passwordResetEmail", username: $email }
    ) {
      clientMutationId
    }
  }
`;

const resetPasswordMutation = gql`
  mutation ResetPassword(
    $password: String!
    $key: String!
    $username: String!
  ) {
    resetUserPassword(
      input: {
        clientMutationId: "resetPassword"
        key: $key
        login: $username
        password: $password
      }
    ) {
      clientMutationId
    }
  }
`;

TopBarProgress.config({
  barThickness: 3,
  barColors: {
    0: '#4caf50',
    '1.0': '#4caf50',
  },
  shadowBlur: 10,
  shadowColor: 'rgba(0,   0,   0,   .6)',
});

function getInitialState(userkey) {
  const user = JSON.parse(localStorage.getItem(userkey));
  return { user };
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'authenticateUser':
      return {
        ...state,
        user: action.user,
      };
    case 'signOut':
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, getInitialState(userKey));

  const signIn = async (userName, password) => {
    const response = await client.mutate({
      mutation: loginMutation,
      variables: { userName, password },
    });
    const {
      data: { login },
    } = response;
    localStorage.setItem(userKey, JSON.stringify(login.user));
    dispatch({ type: 'authenticateUser', user: login.user });
  };

  const sendPasswordResetEmail = (email) => {
    return client.mutate({
      mutation: passwordResetEmailMutation,
      variables: { email },
    });
  };

  const resetPassword = (key, username, password) => {
    return client.mutate({
      mutation: resetPasswordMutation,
      variables: { key, username, password },
    });
  };

  const updateToken = (token) => {
    const user = JSON.parse(localStorage.getItem(userKey));
    const updatedUser = { ...user, token };
    localStorage.setItem(userKey, JSON.stringify(updatedUser));
    dispatch({ type: 'authenticateUser', user: updatedUser });
  };

  const signOut = () => {
    localStorage.clear();
    dispatch({ type: 'signOut' });
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        sendPasswordResetEmail,
        resetPassword,
        updateToken,
        uri,
        userKey,
        user: state.user,
        progress: <TopBarProgress />,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
