import React from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import {
  Alert as MuiAlert,
  Button as MuiButton,
  Link,
  TextField as MuiTextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import useAuth from '../../hooks/useAuth';

const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)`
  font-size: 0.9375rem;
  padding: 0.53rem 1.62rem;
  margin-bottom: 12px;
`;

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const { resetPassword, progress } = useAuth();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        password: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required('Password is required')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character.'
          ),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          await resetPassword(
            searchParams.get('key'),
            searchParams.get('login'),
            values.password
          );
          resetForm();
          setStatus({
            success: true,
            message: 'Your password has been reset.',
          });
        } catch (error) {
          const message = error.message || 'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {isSubmitting && progress}
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {status?.success && (
            <Alert mt={2} mb={1} severity="success">
              {status?.message}{' '}
              <Link
                component="button"
                onClick={() => navigate('/auth/sign-in')}
              >
                Please click here to login.
              </Link>
            </Alert>
          )}
          <TextField
            type="password"
            name="password"
            label="New Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            SET NEW PASSWORD
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
