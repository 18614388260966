import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components/macro';
import { darken } from 'polished';
import {
  ArrowLeft as ArrowLeftIcon,
  Filter as FilterIcon,
  Search as SearchIcon,
} from 'react-feather';

import {
  AppBar as MuiAppBar,
  Chip as MuiChip,
  Grid,
  Hidden,
  InputBase,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
} from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';

import EventsDropdown from './EventsDropdown';
import NavbarUserDropdown from './NavbarUserDropdown';
import useFilters from '../../hooks/useFilters';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Chip = styled(MuiChip)`
  color: #fff;
  height: auto;
  border-radius: 4px;
`;

const Search = styled.div`
  border-radius: 4px;
  background-color: ${(props) => darken(0.05, props.theme.header.background)};
  position: relative;
  display: flex;
  width: 100%;
  margin-right: 1.5rem;
`;

const FilterIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(2.5)};
    width: 160px;
  }
`;

const Navbar = ({ onDrawerToggle, onFilterToggle, isWorkersPage }) => {
  const [isSearch, setSearch] = useState(false);
  const { setCurrentSearch, currentSearch, currentBranch } = useFilters();

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" sx={{ flexWrap: 'nowrap' }}>
            {isWorkersPage && isSearch ? (
              <>
                <IconButton
                  onClick={() => setSearch(false)}
                  color="inherit"
                  size="large"
                >
                  <ArrowLeftIcon />
                </IconButton>
                <Search>
                  {currentBranch && <Chip label={currentBranch.name} />}
                  <Input
                    placeholder="Search"
                    value={currentSearch}
                    onChange={(e) => {
                      setCurrentSearch(e.target.value);
                    }}
                  />
                  <FilterIconWrapper>
                    <IconButton
                      onClick={onFilterToggle}
                      color="inherit"
                      size="large"
                    >
                      <FilterIcon />
                    </IconButton>
                  </FilterIconWrapper>
                </Search>
              </>
            ) : (
              <>
                <Hidden mdUp>
                  <Grid item>
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={onDrawerToggle}
                      size="large"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Hidden>
                <EventsDropdown />
                <Hidden mdDown>
                  <Grid item xs />
                </Hidden>
                <Grid item sx={{ display: 'flex' }}>
                  {isWorkersPage && (
                    <Tooltip title="Search">
                      <IconButton
                        onClick={() => setSearch(true)}
                        color="inherit"
                        size="large"
                      >
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <NavbarUserDropdown />
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
