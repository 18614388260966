import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

function AdminGuard({ children }) {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/auth/sign-in" />;
  }

  const role = user?.roles?.nodes?.map((item) => item.name)[0];
  if (
    user &&
    role === 'contributor' &&
    !location.pathname.includes('/workers')
  ) {
    return <Navigate to="/workers" />;
  }

  if (user && role === 'author' && location.pathname !== '/dashboard') {
    return <Navigate to="/dashboard" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AdminGuard;
