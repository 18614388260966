import React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';

import { Paper, Typography } from '@mui/material';

import { ReactComponent as Logo } from '../../vendor/logo.svg';
import ResetPasswordComponent from '../../components/auth/ResetPassword';

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin: auto;
  display: block;
  margin-bottom: 12px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPassword() {
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Reset Password" />
        <Brand />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Please enter your new password below.
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
