import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { X as Close } from 'react-feather';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { tableCellClasses } from '@mui/material/TableCell';

import LineChart from './LineChart';
import Stats from './Stats';
import BranchTable from './BranchTable';
import useAuth from '../../../hooks/useAuth';
import { tConvert } from '../../../utils/utils';
import useFilters from '../../../hooks/useFilters';

const GET_EVENT = gql`
  query Event($id: ID!) {
    event(id: $id, idType: DATABASE_ID) {
      id: databaseId
      name
      startTime
      endTime
      pastorsAttendance: attendances(
        first: 0
        where: {
          worker_groups_in: [6, 7]
          branch_not_in: [1]
          orderby: { field: ID, order: ASC }
        }
      ) {
        nodes {
          ID: databaseId
          workerId
          workerName
          branchName
          createdAt
        }
        pageInfo {
          total
        }
      }
      asstPastorsAttendance: attendances(
        first: 0
        where: {
          worker_groups_in: [8]
          branch_not_in: [1]
          orderby: { field: ID, order: ASC }
        }
      ) {
        nodes {
          ID: databaseId
          workerId
          workerName
          branchName
          createdAt
        }
        pageInfo {
          total
        }
      }
      workersAttendance: attendances {
        nodes {
          ID: databaseId
          workerName
        }
        pageInfo {
          total
          totalBrothers
          totalSisters
          lineDataBrothers {
            hour
            present
          }
          lineDataSisters {
            hour
            present
          }
          branchData {
            ID: id
            name
            present
          }
        }
      }
    }
  }
`;

const GET_WORKERS_INFO = gql`
  query GetWorkers($eventStartDate: String, $includeAutonomous: Boolean) {
    pastorsInfo: workers(
      first: 0
      where: {
        worker_groups_in: [6, 7]
        branch_not_in: [1]
        event_start_date: $eventStartDate
        include_autonomous: $includeAutonomous
      }
    ) {
      pageInfo {
        total
      }
    }
    asstPastorsInfo: workers(
      first: 0
      where: {
        worker_groups_in: [8]
        branch_not_in: [1]
        event_start_date: $eventStartDate
        include_autonomous: $includeAutonomous
      }
    ) {
      pageInfo {
        total
      }
    }
    workersInfo: workers(
      where: {
        event_start_date: $eventStartDate
        include_autonomous: $includeAutonomous
      }
    ) {
      pageInfo {
        total
        branchData {
          ID: id
          name
          present
          total
          pastorId
          typeId
          regionId
          pastor {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const GET_SETTINGS = gql`
  query GetSettings {
    pollInterval
    lineGraphInterval
  }
`;

const StatsContainer = styled.div`
  cursor: pointer;
`;

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.body} {
    font-size: 0.89rem;
  }
`;

const CustomTableCellName = styled(CustomTableCell)`
  font-weight: 500;
`;

const DialogTitleTextContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DialogTitleText = ({ text, handleclose }) => (
  <DialogTitleTextContainer>
    <span>{text}</span>
    <IconButton
      aria-label="close"
      onClick={handleclose}
      sx={{
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <Close />
    </IconButton>
  </DialogTitleTextContainer>
);

function PastorDialog({ open, handleClose, pastorsAttendance, title, total }) {
  const rows = pastorsAttendance.nodes.map((item) => {
    const { workerId, workerName, branchName, createdAt } = item;
    const time = new Date(createdAt);
    return {
      id: workerId,
      workerName,
      branchName,
      cameBy: tConvert(time),
    };
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <DialogTitleText
          handleclose={handleClose}
          text={
            <>
              {' '}
              {`${title} Attendance`}{' '}
              <small>{`(Present: ${rows.length},  Absent: ${
                total - rows.length
              })`}</small>{' '}
            </>
          }
        />
      </DialogTitle>

      <DialogContent>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>
                <CustomTableCellName>{row.workerName}</CustomTableCellName>
                <CustomTableCell>{row.branchName}</CustomTableCell>
                <CustomTableCell>{row.cameBy}</CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

function WorkerDialog({ open, handleClose, totalBrothers, totalSisters }) {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <DialogTitleText handleclose={handleClose} text="Workers Attendance" />
      </DialogTitle>
      <DialogContent>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <CustomTableCellName>Brothers</CustomTableCellName>
              <CustomTableCell>{totalBrothers}</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCellName>Sisters</CustomTableCellName>
              <CustomTableCell>{totalSisters}</CustomTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

function Default() {
  const { currentEvent } = useFilters();
  const { progress, user } = useAuth();
  const [pastorDialog, setPastorDialog] = useState(null);
  const [workerDialog, setWorkerDialog] = useState(null);
  const { loading: loadingSettings, data: dataSettings } =
    useQuery(GET_SETTINGS);
  const { loading: loadingWorkers, data: dataWorkers } = useQuery(
    GET_WORKERS_INFO,
    {
      variables: {
        eventStartDate: currentEvent.startTime,
        includeAutonomous: currentEvent.includeAuto,
      },
    }
  );
  const [
    getEvent,
    { loading: loadingEvent, data: dataEvent, startPolling, stopPolling },
  ] = useLazyQuery(GET_EVENT);

  useEffect(() => {
    if (currentEvent.id) {
      getEvent({ variables: { id: currentEvent.id } });
    }
  }, [currentEvent]); // eslint-disable-line  react-hooks/exhaustive-deps

  if (
    loadingEvent ||
    loadingWorkers ||
    loadingSettings ||
    !dataEvent ||
    !dataWorkers ||
    !dataSettings
  ) {
    return progress;
  }

  const {
    event: {
      endTime,
      pastorsAttendance,
      asstPastorsAttendance,
      workersAttendance,
    },
  } = dataEvent;

  if (endTime) {
    stopPolling();
  } else {
    startPolling(dataSettings.pollInterval);
  }

  const {
    pastorsInfo: { pageInfo: pastorsPageInfo },
    asstPastorsInfo: { pageInfo: asstPastorsPageInfo },
    workersInfo: { pageInfo: workersPageInfo },
  } = dataWorkers;

  const pastorsPercent = Math.floor(
    (pastorsAttendance.pageInfo.total * 100) / pastorsPageInfo.total
  );
  const asstPastorsPercent = Math.floor(
    (asstPastorsAttendance.pageInfo.total * 100) / asstPastorsPageInfo.total
  );
  const workersPercent = Math.floor(
    (workersAttendance.pageInfo.total * 100) / workersPageInfo.total
  );
  const role = user?.roles?.nodes?.map((item) => item.name)[0];

  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <StatsContainer onClick={() => setPastorDialog('pastor')}>
            <Stats
              title="Pastors Present"
              amount={pastorsAttendance.pageInfo.total}
              endTime={endTime}
              percentagetext={
                isNaN(pastorsPercent) ? '0%' : `${pastorsPercent}%`
              }
              percentagecolor={pastorsPercent > 50 ? green[500] : red[500]}
              total={`Total: ${pastorsPageInfo.total}`}
            />
          </StatsContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StatsContainer onClick={() => setPastorDialog('asstPastor')}>
            <Stats
              title="Asst. Pastors Present"
              amount={asstPastorsAttendance.pageInfo.total}
              endTime={endTime}
              percentagetext={
                isNaN(asstPastorsPercent) ? '0%' : `${asstPastorsPercent}%`
              }
              percentagecolor={asstPastorsPercent > 50 ? green[500] : red[500]}
              total={`Total: ${asstPastorsPageInfo.total}`}
            />
          </StatsContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StatsContainer onClick={() => setWorkerDialog(true)}>
            <Stats
              title="Workers Present"
              amount={workersAttendance.pageInfo.total}
              endTime={endTime}
              percentagetext={`${workersPercent}%`}
              percentagecolor={workersPercent > 50 ? green[500] : red[500]}
              total={`Total: ${workersPageInfo.total}`}
            />
          </StatsContainer>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <LineChart
            workersPageInfo={workersAttendance.pageInfo}
            endTime={endTime}
          />
        </Grid>
        <Grid item xs={12}>
          <BranchTable
            workersAttendance={workersAttendance}
            dataBranch={workersPageInfo.branchData}
            pastorsAttendance={pastorsAttendance}
            currentEventId={currentEvent.id}
            includeAutonomous={currentEvent.includeAuto}
            endTime={endTime}
            role={role}
          />
        </Grid>
      </Grid>
      {pastorDialog && (
        <PastorDialog
          open={pastorDialog ? true : false}
          handleClose={() => setPastorDialog(null)}
          pastorsAttendance={
            pastorDialog === 'pastor'
              ? pastorsAttendance
              : asstPastorsAttendance
          }
          title={pastorDialog === 'pastor' ? 'Pastors' : 'Assistant Pastors'}
          total={
            pastorDialog === 'pastor'
              ? pastorsPageInfo.total
              : asstPastorsPageInfo.total
          }
        />
      )}
      {workerDialog && (
        <WorkerDialog
          open={workerDialog ? true : false}
          handleClose={() => setWorkerDialog(null)}
          totalBrothers={workersAttendance.pageInfo.totalBrothers}
          totalSisters={workersAttendance.pageInfo.totalSisters}
        />
      )}
    </React.Fragment>
  );
}

export default Default;
