import React from 'react';
import {
  ApolloClient,
  ApolloProvider as Provider,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
  gql,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { fromPromise } from '@apollo/client/link/utils';
import useAuth from '../hooks/useAuth';

export const ApolloProvider = ({ children }) => {
  const { uri, user, userKey, signOut } = useAuth();
  const { refreshToken } = user || {};

  const httpLink = new HttpLink({ uri });
  const authLink = new ApolloLink((operation, forward) => {
    const user = JSON.parse(localStorage.getItem(userKey));
    const { token } = user || {};
    operation.setContext(({ headers }) => ({
      headers: {
        ...(token && { authorization: `Bearer ${token}` }),
        ...headers,
      },
    }));
    return forward(operation);
  });

  const variables = { refreshToken };
  const mutation = gql`
    mutation RefreshAuthToken($refreshToken: String!) {
      refreshJwtAuthToken(
        input: {
          clientMutationId: "refreshToken"
          jwtRefreshToken: $refreshToken
        }
      ) {
        authToken
      }
    }
  `;

  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const refreshClient = new ApolloClient({
        cache: new InMemoryCache(),
        uri,
      });
      for (let err of graphQLErrors) {
        switch (err.message) {
          case 'Expired token':
            return fromPromise(
              refreshClient
                .mutate({ mutation, variables })
                .then(({ data }) => {
                  return data.refreshJwtAuthToken.authToken;
                })
                .catch((e) => {
                  if (e.message === 'The provided refresh token is invalid') {
                    signOut();
                  }
                })
            )
              .filter((value) => Boolean(value))
              .flatMap((authToken) => {
                const headers = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${authToken}`,
                  },
                });

                const user = JSON.parse(localStorage.getItem(userKey));
                const updatedUser = { ...user, token: authToken };
                localStorage.setItem(userKey, JSON.stringify(updatedUser));
                // retry the request, returning the new observable
                return forward(operation);
              });
          case 'Signature verification failed':
            signOut();
            break;
          default:
            console.log(err.message);
        }
      }
    }
  });
  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            worker(_, { args, toReference }) {
              return toReference({
                __typename: 'Worker',
                id: args.id,
              });
            },
            event(_, { args, toReference }) {
              return toReference({
                __typename: 'Event',
                id: args.id,
              });
            },
            workers: relayStylePagination(['first', 'where']),
          },
        },
        Worker: {
          fields: {
            attendances: relayStylePagination(['first', 'where']),
          },
        },
      },
    }),
    link: from([authLink, errorLink, httpLink]),
  });

  return <Provider client={client}>{children}</Provider>;
};
