import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import {
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell as MuiTableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { spacing } from '@mui/system';
import useAuth from '../../hooks/useAuth';
import useFilters from '../../hooks/useFilters';
import { ButtonDropdown, Search } from '../../utils/components';

export const GET_BRANCHES = gql`
  query GetBranches {
    branches(first: 0, where: { orderby: { field: NAME, order: ASC } }) {
      edges {
        node {
          id: databaseId
          name
          address
          typeId
          regionId
        }
      }
      pageInfo {
        totalBrothers
        totalSisters
        branchData {
          id
          total
          pastor {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const Paper = styled(MuiPaper)(spacing);

const BranchStat = styled.small`
  font-size: 13px;
`;

const CountStat = styled.span`
  color: ${(props) => props.theme.palette.primary.main};
`;

const TableCell = styled(MuiTableCell)`
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const TableCellName = styled(TableCell)`
  font-weight: 500;
`;

const FilterGrid = styled(Grid)`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 0 !important;
`;

const BranchGrid = styled(Grid)`
  padding-top: 10px !important;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
  },
  {
    id: 'pastor',
    numeric: false,
    disablePadding: false,
    label: 'Pastor',
  },
  {
    id: 'region',
    numeric: false,
    disablePadding: false,
    label: 'Region',
  },
  {
    id: 'total',
    numeric: false,
    disablePadding: false,
    label: 'Total Workers',
  },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function BranchesList({
  branchEdges,
  branchData,
  regionOptions,
  searchTerm,
  regionId,
  pageMeta,
  setPageMeta,
}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(pageMeta?.branches?.page || 0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm || regionId) {
      setPage(0);
    }
  }, [searchTerm, regionId]);

  useEffect(() => {
    const pageScroll = pageMeta?.branches?.scroll;
    document.getElementById('root').scrollTop = pageScroll;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const rows = branchEdges.map((item) => {
    const node = item.node;
    const branch = branchData.find((br) => br.id === node.id);

    return {
      id: node.id,
      name: node.name,
      address: node.address,
      pastor: `${branch?.pastor?.firstName || '-'} ${
        branch?.pastor?.lastName || ''
      }`,
      total: branch?.total,
      region: regionOptions.find((item) => item.value === node.regionId)?.label,
    };
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (row.id !== 1) {
                          setPageMeta([
                            {
                              name: 'branches',
                              page,
                              term: searchTerm,
                              scroll: document.getElementById('root').scrollTop,
                            },
                            { name: 'branch', page: 0, term: '', scroll: 0 },
                          ]);
                          navigate(`/branches/${row.id}`);
                        }
                      }}
                    >
                      <TableCellName>{row.name}</TableCellName>
                      <TableCell>{row.address}</TableCell>
                      <TableCellName>{row.pastor}</TableCellName>
                      <TableCell>{row.region}</TableCell>
                      <TableCell>{row.total}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function Branches() {
  const { loading, data } = useQuery(GET_BRANCHES);
  const { currentRegion, setCurrentRegion, pageMeta, setPageMeta } =
    useFilters();
  const [searchTerm, setSearchTerm] = useState(pageMeta?.branches?.term || '');
  const { progress } = useAuth();

  if (loading || !data) {
    return progress;
  }

  const {
    branches: { edges, pageInfo },
  } = data;

  const regionOptions = edges
    .filter((item) => [4, 5].includes(item.node.typeId))
    .map((item) => {
      return {
        value: item.node.id,
        label: `${item.node.name} ${
          item.node.typeId === 4 ? 'Autonomous Region' : 'Region'
        }`,
      };
    });
  let branchEdges = edges;
  if (currentRegion) {
    branchEdges = branchEdges.filter(
      (item) => item.node.regionId === currentRegion
    );
  }
  if (searchTerm) {
    branchEdges = branchEdges.filter((item) =>
      item.node.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Branches" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Branches
          </Typography>
          <br />
          <BranchStat>
            Regions:<CountStat>{regionOptions.length} </CountStat>
            Branches:<CountStat>{edges.length} </CountStat>
            Workers:
            <CountStat>
              {pageInfo.totalBrothers + pageInfo.totalSisters}{' '}
            </CountStat>
            Brothers:<CountStat>{pageInfo.totalBrothers} </CountStat>
            Sisters:<CountStat>{pageInfo.totalSisters} </CountStat>
          </BranchStat>
        </Grid>
      </Grid>

      <Grid container spacing={6} mt={2}>
        <FilterGrid item xs={12}>
          <Search value={searchTerm} onChange={setSearchTerm} />
          <ButtonDropdown
            options={regionOptions}
            handleChange={setCurrentRegion}
            value={currentRegion}
            allLabel={'All Regions'}
          />
        </FilterGrid>
        <BranchGrid item xs={12}>
          <BranchesList
            branchEdges={branchEdges}
            branchData={pageInfo.branchData}
            regionOptions={regionOptions}
            searchTerm={searchTerm}
            regionId={currentRegion}
            pageMeta={pageMeta}
            setPageMeta={setPageMeta}
          />
        </BranchGrid>
      </Grid>
    </React.Fragment>
  );
}

export default Branches;
