import React from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import {
  Alert as MuiAlert,
  Button as MuiButton,
  Box as MuiBox,
  Checkbox,
  FormControlLabel,
  Link as MuiLink,
  TextField as MuiTextField,
} from '@mui/material';
import { spacing } from '@mui/system';

import useAuth from '../../hooks/useAuth';

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Box = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Link = styled(MuiLink)`
  font-size: 14px;
  cursor: pointer;
`;

const Button = styled(MuiButton)`
  font-size: 0.9375rem;
  padding: 0.53rem 1.62rem;
`;

function SignIn() {
  const { signIn, progress } = useAuth();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        userName: '',
        password: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        userName: Yup.string().max(255).required('Username is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.userName, values.password);
        } catch (error) {
          const message = error.message || 'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message.replace('_', ' ') });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {isSubmitting && progress}
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            name="userName"
            label="Username or Email"
            value={values.userName}
            error={Boolean(touched.userName && errors.userName)}
            fullWidth
            helperText={touched.userName && errors.userName}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Box>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />{' '}
            <Link
              component="span"
              onClick={() => navigate('/auth/lost-password')}
              color="primary"
              underline="none"
            >
              Forgot Password
            </Link>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            LOGIN
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
