import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import {
  Button,
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';

import useAuth from '../../hooks/useAuth';
import useFilters from '../../hooks/useFilters';
import { BranchDropdown } from '../../utils/components';
import ActionAlert from '../components/ActionAlert';

const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)`
  width: 100%;
`;

const GET_WORKER = gql`
  query Worker($id: ID!) {
    worker(id: $id, idType: DATABASE_ID) {
      id: databaseId
      firstName
      otherName
      lastName
      gender
      maritalStatus
      address
      lga
      state
      country
      phone
      email
      pictureUrl
      membershipYear
      subBranchId
      branch {
        id: databaseId
        name
      }
      workerGroups {
        nodes {
          ID: databaseId
          groupId
          groupLevel
          groupLevelId
          groupName
        }
      }
      attendances(first: 5) {
        nodes {
          ID: databaseId
          eventId
          eventName
          eventStarted
          createdAt
        }
      }
    }
  }
`;

const UPDATE_WORKER = gql`
  mutation updateWorker(
    $workerId: Int!
    $firstName: String!
    $otherName: String!
    $lastName: String!
    $gender: String!
    $maritalStatus: String!
    $email: String!
    $phone: String!
    $branchId: Int!
    $subBranchId: Int!
    $eventId: Int!
  ) {
    updateWorker(
      input: {
        clientMutationId: "updateWorker"
        workerId: $workerId
        firstName: $firstName
        otherName: $otherName
        lastName: $lastName
        gender: $gender
        maritalStatus: $maritalStatus
        email: $email
        phone: $phone
        branchId: $branchId
        subBranchId: $subBranchId
        eventId: $eventId
      }
    ) {
      worker {
        id: databaseId
        branch {
          id: databaseId
          name
        }
      }
    }
  }
`;

const getInitialState = (worker) => {
  return {
    firstName: worker.firstName,
    lastName: worker.lastName,
    otherName: worker.otherName || '',
    gender: worker.gender || '',
    maritalStatus: worker.maritalStatus || '',
    email: worker.email || '',
    phone: worker.phone,
    branch: { id: worker.branch.id, name: worker.branch.name },
    subBranchId: worker.subBranchId,
  };
};

function EditorForm({ worker, progress, currentEvent }) {
  const [state, setState] = useState(getInitialState(worker));
  const [message, setMessage] = useState({});
  const [updateWorker, { loading }] = useMutation(UPDATE_WORKER, {
    variables: {
      ...state,
      branchId: state?.branch?.id,
      workerId: parseInt(worker.id),
      eventId: currentEvent.endTime === null ? currentEvent.id : 0,
    },
    update(cache, { data: updatedWorkerData }) {
      const {
        updateWorker: { worker: updatedWorker },
      } = updatedWorkerData;
      cache.evict({ id: `Worker:${worker.id}` });
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: `workers:{"first":0,"where":{"branchId":${updatedWorker.branch.id},"orderby":{"field":"FIRST_NAME","order":"ASC"},"search":""}}`,
      });
      if (updatedWorker.branch.id !== worker.branch.id) {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: `workers:{"first":0,"where":{"branchId":${worker.branch.id},"orderby":{"field":"FIRST_NAME","order":"ASC"},"search":""}}`,
        });
      }
    },
    onCompleted() {
      setMessage({ text: 'Worker updated' });
    },
    onError(e) {
      setMessage({ text: e.message, severity: 'error' });
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBranchChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      branch: value,
    }));
  };

  const maritalStatusOptions = [
    { value: 'S', name: 'Single' },
    { value: 'M', name: 'Married' },
    { value: 'D', name: 'Divorced' },
    { value: 'W', name: 'Widowed' },
  ];

  const subBranchOptions = [
    { value: 0, name: 'None' },
    { value: 1, name: 'Aguda' },
    { value: 2, name: 'Ijesha' },
    { value: 3, name: 'Itire' },
  ];

  const genderOptions = [
    { value: 'M', name: 'Male' },
    { value: 'F', name: 'Female' },
  ];

  return (
    <>
      {loading && progress}
      {message.text && (
        <ActionAlert
          message={message.text}
          severity={message.severity}
          setMessage={setMessage}
        />
      )}
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom mb={6}>
            Edit Worker
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateWorker();
            }}
          >
            <Grid container spacing={6} mb={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  label="First Name"
                  value={state.firstName}
                  fullWidth
                  required
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="otherName"
                  label="Other Name"
                  value={state.otherName}
                  fullWidth
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  value={state.lastName}
                  fullWidth
                  required
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined">
                  <InputLabel id="gender-select-outlined">Gender</InputLabel>
                  <Select
                    labelId="gender-select-outlined"
                    name="gender"
                    label="Gender"
                    value={state.gender}
                    onChange={handleChange}
                  >
                    <MenuItem>
                      <em>None</em>
                    </MenuItem>
                    {genderOptions.map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined">
                  <InputLabel id="marital-status-select-outlined">
                    Marital Status
                  </InputLabel>
                  <Select
                    labelId="marital-status-select-outlined"
                    name="maritalStatus"
                    label="Marital Status"
                    value={state.maritalStatus}
                    onChange={handleChange}
                  >
                    <MenuItem>
                      <em>None</em>
                    </MenuItem>
                    {maritalStatusOptions.map((status, index) => (
                      <MenuItem value={status.value} key={index}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  value={state.email}
                  fullWidth
                  onChange={handleChange}
                  variant="outlined"
                  type="email"
                  my={2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phone"
                  label="Phone"
                  value={state.phone}
                  fullWidth
                  required
                  onChange={handleChange}
                  variant="outlined"
                  type="number"
                  my={2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BranchDropdown
                  label="Branch"
                  value={state.branch}
                  handleChange={handleBranchChange}
                  required
                />
              </Grid>
              {worker.branch.id === 1 && (
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined">
                    <InputLabel id="sub-branch-select-outlined">
                      Sub Branch
                    </InputLabel>
                    <Select
                      labelId="sub-branch-select-outlined"
                      name="subBranchId"
                      label="Sub Branch"
                      value={state.subBranchId}
                      onChange={handleChange}
                    >
                      {subBranchOptions.map((item, index) => (
                        <MenuItem value={item.value} key={index}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                JSON.stringify(state) ===
                  JSON.stringify(getInitialState(worker)) || loading
                  ? true
                  : false
              }
            >
              Edit Worker
            </Button>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

function Editor() {
  const { id } = useParams();
  const { loading, data } = useQuery(GET_WORKER, { variables: { id } });
  const { progress } = useAuth();
  const { currentEvent } = useFilters();

  if (loading || !data) {
    return progress;
  }

  return (
    <React.Fragment>
      <Helmet title="Worker Editor" />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EditorForm
            worker={data.worker}
            currentEvent={currentEvent}
            progress={progress}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Editor;
