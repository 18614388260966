import React from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { ChevronLeft } from 'react-feather';

import {
  Alert as MuiAlert,
  Button as MuiButton,
  Link as MuiLink,
  TextField as MuiTextField,
} from '@mui/material';
import { spacing } from '@mui/system';

import useAuth from '../../hooks/useAuth';

const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)`
  margin-bottom: 16px;
  font-size: 0.9375rem;
  padding: 0.53rem 1.62rem;
`;

const Link = styled(MuiLink)`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const TextField = styled(MuiTextField)(spacing);

function LostPassword() {
  const { sendPasswordResetEmail, progress } = useAuth();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        email: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          await sendPasswordResetEmail(values.email);
          resetForm();
          setStatus({
            success: true,
            message:
              "An email has been sent with instructions to reset your password.  If you don't see it within a few minutes, please check your spam folder.",
          });
        } catch (error) {
          const message = error.message || 'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {isSubmitting && progress}
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {status?.success && (
            <Alert mt={2} mb={1} severity="success">
              {status?.message}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            mb={4}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            SEND RESET LINK
          </Button>
          <Link
            component="button"
            underline="none"
            onClick={() => navigate('/auth/sign-in')}
          >
            <ChevronLeft /> Back to Login
          </Link>
        </form>
      )}
    </Formik>
  );
}

export default LostPassword;
