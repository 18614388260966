import React from 'react';
import { Navigate } from 'react-router-dom';

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

// Guards
import GuestGuard from './components/guards/GuestGuard';
import AdminGuard from './components/guards/AdminGuard';

// Auth components
import SignIn from './pages/auth/SignIn';
import Page404 from './pages/auth/Page404';
import Page500 from './pages/auth/Page500';
import LostPassword from './pages/auth/LostPassword';
import ResetPassword from './pages/auth/ResetPassword';

// Page components
import Workers from './pages/pages/Workers';
import Worker from './pages/pages/Worker';
import WorkerEditor from './pages/pages/WorkerEditor';
import Branches from './pages/pages/Branches';
import Branch from './pages/pages/Branch';
import Settings from './pages/pages/Settings';

// Dashboard components
import Default from './pages/dashboards/Default';

const routes = [
  {
    path: '/',
    element: <Navigate to="/dashboard" />,
  },
  {
    path: 'dashboard',
    element: (
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: '',
        element: <Default />,
      },
    ],
  },
  {
    path: 'workers',
    element: (
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: '',
        element: <Workers />,
      },
      {
        path: ':id',
        element: <Worker />,
      },
      {
        path: 'edit/:id',
        element: <WorkerEditor />,
      },
    ],
  },
  {
    path: 'branches',
    element: (
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: '',
        element: <Branches />,
      },
      {
        path: ':id',
        element: <Branch />,
      },
    ],
  },
  {
    path: 'settings',
    element: (
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: '',
        element: <Settings />,
      },
    ],
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in',
        element: (
          <GuestGuard>
            <SignIn />
          </GuestGuard>
        ),
      },
      {
        path: 'lost-password',
        element: (
          <GuestGuard>
            <LostPassword />
          </GuestGuard>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <GuestGuard>
            <ResetPassword />
          </GuestGuard>
        ),
      },
      {
        path: '404',
        element: <Page404 />,
      },
      {
        path: '500',
        element: <Page500 />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
