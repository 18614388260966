export function attendanceGrade(eventStarted, itemCreated) {
  const diffMin =
    Math.abs(new Date(eventStarted) - new Date(itemCreated)) / (1000 * 60);
  switch (true) {
    case diffMin <= 0:
      return 100;
    case diffMin <= 30:
      return 80;
    case diffMin <= 60:
      return 60;
    default:
      return 40;
  }
}

export function tConvert(timeString) {
  return new Date(timeString).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

export function stringToColour(str) {
  let hash = 0;

  [...str].forEach((_, i) => {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  });

  const rgb = [0, 0, 0];
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }
  return {
    color: `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`,
    background: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.12)`,
  };
}
