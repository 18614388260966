import React from 'react';
import styled from 'styled-components/macro';

import useFilters from '../../hooks/useFilters';
import { BranchDropdown } from '../../utils/components';

const FilterContainer = styled.div`
  margin: 15px 8px;
`;

const SidebarFilter = () => {
  const { currentBranch, setCurrentBranch } = useFilters();

  return (
    <FilterContainer>
      <BranchDropdown value={currentBranch} handleChange={setCurrentBranch} />
    </FilterContainer>
  );
};

export default SidebarFilter;
