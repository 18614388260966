import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams, useNavigate } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import CalendarHeatmap from 'react-calendar-heatmap';
import { formatDistance, subYears, addDays } from 'date-fns';
import reactHeatMapStyles from 'react-calendar-heatmap/dist/styles.css'; // eslint-disable-line no-unused-vars

import { Briefcase, Home, Mail, Phone, Plus, Trash } from 'react-feather';
import { Edit as MuiEdit } from '@mui/icons-material';

import {
  Avatar as MuiAvatar,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab as MuiFab,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Skeleton,
  TextField,
  Tooltip,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Timeline as MuiTimeline,
  TimelineItem as MuiTimelineItem,
  TimelineSeparator as MuiTimelineSeparator,
  TimelineConnector,
  TimelineContent as MuiTimelineContent,
  TimelineDot,
} from '@mui/lab';
import { spacing } from '@mui/system';

import useAuth from '../../hooks/useAuth';
import useFilters from '../../hooks/useFilters';
import { attendanceGrade, tConvert, stringToColour } from '../../utils/utils';
import ActionAlert from '../components/ActionAlert';

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Edit = styled(MuiEdit)`
  width: 1.3em;
  height: 1.3em;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  border-radius: 6px;
`;

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(6)};
  bottom: ${(props) => props.theme.spacing(6)};
  z-index: 1;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ActionIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(1)};

  svg {
    width: 18px;
    height: 18px;
  }
`;

const Timeline = styled(MuiTimeline)`
  align-items: flex-start;
  padding: 6px 0;
  width: 100%;
`;

const TimelineItem = styled(MuiTimelineItem)`
  width: 100%;
  &:before {
    padding: 0;
    flex: none;
  }
`;

const TimelineContent = styled(MuiTimelineContent)`
  padding-right: 0;
`;

const TimelineSeparator = styled(MuiTimelineSeparator)`
  & .color-scale-40 {
    background-color: #d6e685;
  }
  & .color-scale-60 {
    background-color: #8cc665;
  }
  & .color-scale-80 {
    background-color: #44a340;
  }
  & .color-scale-100 {
    background-color: #1e6823;
  }
`;

const TimelineEventDetail = styled(Typography)`
  color: rgba(58, 53, 65, 0.68);
`;

const TimelineEventTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonLoadMore = styled(Button)`
  width: 100%;
  color: #44a340;
  border-color: rgba(58, 53, 65, 0.38);
  &:hover {
    border-color: rgba(58, 53, 65, 0.38);
  }
`;

const CalendarWrapper = styled.div`
  overflow: hidden;
  position: relative;
  ${(props) => props.theme.breakpoints.down('md')} {
    & > svg {
      min-width: 895px;
      float: right;
    }
  }
  & .react-calendar-heatmap text {
    fill: inherit;
    font-size: 8px;
  }
  & .react-calendar-heatmap .color-scale-40 {
    fill: #d6e685;
  }
  & .react-calendar-heatmap .color-scale-60 {
    fill: #8cc665;
  }
  & .react-calendar-heatmap .color-scale-80 {
    fill: #44a340;
  }
  & .react-calendar-heatmap .color-scale-100 {
    fill: #1e6823;
  }
  & .react-calendar-heatmap-legend rect:hover {
    stroke: none;
  }
`;

const GET_WORKER = gql`
  query Worker($id: ID!) {
    worker(id: $id, idType: DATABASE_ID) {
      id: databaseId
      firstName
      otherName
      lastName
      gender
      maritalStatus
      address
      lga
      state
      country
      phone
      email
      smsStatus
      pictureUrl
      membershipYear
      subBranchId
      branch {
        id: databaseId
        name
      }
      workerGroups {
        nodes {
          ID: databaseId
          groupId
          groupLevel
          groupLevelId
          groupName
        }
      }
      attendances(first: 5) {
        edges {
          node {
            ID: databaseId
            eventId
            eventName
            eventStarted
            createdAt
          }
        }
      }
    }
  }
`;

const GET_WORKER_YEAR_ATTENDANCE = gql`
  query Worker($id: ID!, $dateRange: AttendanceDateQueryInput) {
    worker(id: $id, idType: DATABASE_ID) {
      id: databaseId
      attendances(first: 0, where: { dateQuery: $dateRange }) {
        nodes {
          ID: databaseId
          eventId
          eventName
          eventTopic
          eventStarted
          eventEnded
          createdAt
        }
      }
    }
  }
`;

const GET_WORKER_ATTENDANCE = gql`
  query Worker($id: ID!, $cursor: String) {
    worker(id: $id, idType: DATABASE_ID) {
      id: databaseId
      attendances(first: 5, after: $cursor) {
        edges {
          node {
            ID: databaseId
            eventId
            eventName
            eventTopic
            eventStarted
            eventEnded
            createdAt
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          total
        }
      }
    }
  }
`;

const ADD_ATTENDANCE = gql`
  mutation AddEventAttendance(
    $eventId: Int!
    $workerId: Int!
    $isPastor: Boolean!
    $branchId: Int!
    $userId: Int!
  ) {
    addAttendance(
      input: {
        clientMutationId: "addAttendance"
        eventId: $eventId
        workerId: $workerId
        isPastor: $isPastor
        branchId: $branchId
        userId: $userId
      }
    ) {
      attendance {
        ID: databaseId
        eventId
        eventName
        eventStarted
        createdAt
      }
    }
  }
`;

const DELETE_ATTENDANCE = gql`
  mutation DeleteEventAttendance($attendanceId: Int!, $eventId: Int!) {
    deleteAttendance(
      input: {
        clientMutationId: "deleteAttendance"
        attendanceId: $attendanceId
        eventId: $eventId
      }
    ) {
      attendanceId
    }
  }
`;

function Details({
  firstName,
  lastName,
  gender,
  pictureUrl,
  branchId,
  currentEvent,
  workerGroups,
  hasAttendance,
  addAttendance,
  loadingAddAttendance,
  deleteAttendance,
  loadingDeleteAttendance,
}) {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const avatarDimension = isMdDown ? '220px' : '188px';
  const stringColor = stringToColour(`${firstName}-${lastName}`);
  const positions = workerGroups.nodes.reduce((acc, item) => {
    return `${acc ? `${acc} -` : ''} ${item.groupLevel}`;
  }, '');
  const isPastor =
    positions.includes('Pastor') && gender === 'M' ? true : false;

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Profile
        </Typography>

        <Centered>
          {pictureUrl ? (
            <Avatar
              alt={`${firstName} ${lastName}`}
              src={pictureUrl}
              sx={{ width: avatarDimension, height: avatarDimension }}
            />
          ) : (
            <Avatar
              sx={{
                fontSize: '3rem',
                lineHeight: avatarDimension,
                color: stringColor.color,
                background: stringColor.background,
                width: avatarDimension,
                height: avatarDimension,
              }}
              alt={`${firstName} ${lastName}`}
            >
              {firstName.charAt(0)}
              {lastName.charAt(0)}
            </Avatar>
          )}
          <Typography variant="h4">{`${firstName} ${lastName}`}</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            <Box fontWeight="fontWeightRegular">{positions}</Box>
          </Typography>

          {hasAttendance ? (
            <Button
              variant="contained"
              color="error"
              onClick={() =>
                deleteAttendance({
                  variables: { attendanceId: hasAttendance.node.ID },
                })
              }
              disabled={
                loadingDeleteAttendance || currentEvent.endTime !== null
              }
            >
              <ActionIcon>
                <Trash />
              </ActionIcon>{' '}
              DELETE ATTENDANCE
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                addAttendance({
                  variables: { branchId, isPastor },
                })
              }
              disabled={loadingAddAttendance || currentEvent.endTime !== null}
            >
              <ActionIcon>
                <Plus />
              </ActionIcon>{' '}
              ADD ATTENDANCE
            </Button>
          )}
        </Centered>
      </CardContent>
    </Card>
  );
}

function Departments({ workerGroups }) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Departments
        </Typography>

        <Spacer mb={4} />
        {workerGroups.nodes
          .map((item) => item.groupName)
          .filter((value, index, array) => array.indexOf(value) === index)
          .map((label, index) => (
            <Chip size="small" mr={1} mb={1} label={label} key={index} />
          ))}
      </CardContent>
    </Card>
  );
}

function Contact({
  address,
  phone,
  email,
  lga,
  state,
  country,
  branch,
  subBranchId,
  smsStatus,
  membershipYear,
}) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Contact
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Home />
            </AboutIcon>
          </Grid>
          <Grid item>
            Branch{' '}
            <Typography component="span" color="primary">
              {branch.name}
              {branch.id === 1 && subBranchId === 0 && <span>*</span>}
            </Typography>
          </Grid>
        </Grid>
        {membershipYear && (
          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <Briefcase />
              </AboutIcon>
            </Grid>
            <Grid item>
              Member since{' '}
              <Typography component="span" color="primary">
                {membershipYear}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Typography
                component="span"
                sx={{
                  color: !smsStatus
                    ? 'inherit'
                    : smsStatus === 'Success'
                    ? 'green'
                    : 'red',
                }}
              >
                <Phone />
              </Typography>
            </AboutIcon>
          </Grid>
          <Grid item>
            Phone{' '}
            <Typography component="span" color="primary">
              {phone}
            </Typography>
          </Grid>
        </Grid>
        {email && (
          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <Mail />
              </AboutIcon>
            </Grid>
            <Grid item>
              Email{' '}
              <Typography component="span" color="primary">
                {email}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid alignItems="center" mb={2}>
          Lives at{' '}
          <Typography component="span" color="primary" marginLeft="5px">
            {`${address || ''}`}
            {` ${lga || ''} ${state} ${country}`}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
}

function CalendarActivity({ id }) {
  const { loading, data } = useQuery(GET_WORKER_YEAR_ATTENDANCE, {
    variables: { id, dateRange: oneYeardateRage() },
  });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const height = matches ? '222px' : '279px';

  if (loading || !data) {
    return (
      <Card mb={6}>
        <Skeleton variant="rectangular" width="100%" sx={{ height }} />
      </Card>
    );
  }

  const {
    worker: { attendances },
  } = data;

  const values = attendances.nodes.map((item) => {
    return {
      date: item.eventStarted,
      name: item.eventName,
      createdAt: item.createdAt,
      count: attendanceGrade(item.eventStarted, item.createdAt),
    };
  });

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Activity Calendar
        </Typography>

        <Spacer mb={6} />

        <CalendarWrapper>
          <CalendarHeatmap
            startDate={subYears(new Date(), 1)}
            endDate={new Date()}
            values={values}
            showWeekdayLabels={true}
            gutterSize={2}
            classForValue={(value) => {
              if (!value) {
                return 'color-empty';
              }
              return `color-scale-${value.count}`;
            }}
            transformDayElement={(element, value, index) => {
              if (!value) {
                return React.cloneElement(element, { key: index });
              }
              const time = tConvert(value.createdAt);
              return React.createElement(
                Tooltip,
                {
                  title: `${value.name} -- came by ${time}`,
                  arrow: true,
                  disableFocusListener: true,
                  placement: 'top',
                  key: index,
                },
                React.cloneElement(element)
              );
            }}
          />
        </CalendarWrapper>
        <Typography variant="body1" gutterBottom>
          {`${values.length} attendance${
            values.length > 1 ? 's' : ''
          } in the last year`}
        </Typography>
      </CardContent>
    </Card>
  );
}

function TimelineActivity({ id, progress }) {
  const { loading, data, fetchMore } = useQuery(GET_WORKER_ATTENDANCE, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  if (!data) {
    return null;
  }
  const {
    worker: {
      attendances: { edges },
    },
  } = data;
  const pageInfo = data.worker.attendances.pageInfo;

  return (
    <Card mb={6}>
      {loading && progress}
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Activity Timeline
        </Typography>

        <Timeline>
          {edges.map((node, index, items) => {
            const item = node.node;
            const colorScale = `color-scale-${attendanceGrade(
              item.eventStarted,
              item.createdAt
            )}`;
            const cameAtTime = tConvert(item.createdAt);
            const startTime = tConvert(item.eventStarted);
            const endTime = item.eventEnded && tConvert(item.eventEnded);
            return (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot className={colorScale} />
                  {index + 1 !== items.length && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <TimelineEventTitle>
                    <Typography variant="h6" fontWeight={'400'} mr={2}>
                      {item.eventName}
                    </Typography>
                    <TimelineEventDetail variant="body2">
                      {formatDistance(new Date(item.createdAt), new Date(), {
                        addSuffix: true,
                      }).replace(/about|over/g, '')}
                    </TimelineEventDetail>
                  </TimelineEventTitle>
                  <TimelineEventDetail variant="body2">
                    {`Duration: ${startTime} - ${endTime}`}
                  </TimelineEventDetail>
                  {item.eventTopic && (
                    <TimelineEventDetail variant="body2">
                      {`Topic: ${item.eventTopic}`}
                    </TimelineEventDetail>
                  )}
                  <TimelineEventDetail variant="body2">
                    {`Came at: ${cameAtTime}`}
                  </TimelineEventDetail>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
        <Spacer mb={6} />
        {pageInfo.hasNextPage && (
          <ButtonLoadMore
            variant="outlined"
            onClick={() =>
              fetchMore({
                variables: { cursor: pageInfo.endCursor },
              })
            }
            disabled={loading}
          >
            Show More
          </ButtonLoadMore>
        )}
      </CardContent>
    </Card>
  );
}

function SendMessage({ open, handleClose, firstName, lastName }) {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"></DialogTitle>
      <DialogContent>
        <Paper mt={4}>
          <TextField
            label={`Message for ${firstName} ${lastName}`}
            required
            multiline
            fullWidth
            rows={4}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            mt={3}
            disabled={true}
          >
            Send
          </Button>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}

function oneYeardateRage() {
  const date = new Date();
  const beforeDate = addDays(date, 1)
    .toJSON()
    .slice(0, 10)
    .split('-')
    .map((item) => parseInt(item));
  const afterDate = subYears(date, 1)
    .toJSON()
    .slice(0, 10)
    .split('-')
    .map((item) => parseInt(item));

  return {
    before: { day: beforeDate[2], month: beforeDate[1], year: beforeDate[0] },
    after: { day: afterDate[2], month: afterDate[1], year: afterDate[0] },
  };
}

function Worker() {
  const { id } = useParams();
  const { loading, data } = useQuery(GET_WORKER, {
    variables: { id },
  });
  const { currentEvent } = useFilters();
  const [message, setMessage] = useState({});
  const navigate = useNavigate();
  const { progress, user } = useAuth();
  const [sendMessage, setSendMessage] = useState(false);
  const [addAttendance, { loading: loadingAddAttendance }] = useMutation(
    ADD_ATTENDANCE,
    {
      variables: {
        workerId: parseFloat(id),
        eventId: currentEvent.id,
        userId: user.id,
      },
      update(
        cache,
        {
          data: {
            addAttendance: { attendance },
          },
        }
      ) {
        cache.modify({
          id: `Worker:${id}`,
          fields: {
            attendances(existingAttendances = {}, { storeFieldName }) {
              if (storeFieldName.includes('where')) {
                return {
                  ...existingAttendances,
                  nodes: existingAttendances.nodes.concat(attendance),
                };
              }
              return {
                ...existingAttendances,
                edges: [
                  {
                    __typename: 'WorkerToAttendanceConnectionEdge',
                    node: attendance,
                  },
                  ...existingAttendances.edges,
                ],
              };
            },
          },
        });
      },
      onCompleted() {
        setMessage({ text: 'Attendance added' });
      },
      onError(e) {
        setMessage({ text: e.message, severity: 'error' });
      },
    }
  );
  const [deleteAttendance, { loading: loadingDeleteAttendance }] = useMutation(
    DELETE_ATTENDANCE,
    {
      variables: {
        eventId: currentEvent.id,
      },
      update(
        cache,
        {
          data: {
            deleteAttendance: { attendanceId },
          },
        }
      ) {
        cache.modify({
          id: `Worker:${id}`,
          fields: {
            attendances(existingAttendances = {}, { storeFieldName }) {
              if (storeFieldName.includes('where')) {
                return {
                  ...existingAttendances,
                  nodes: existingAttendances.nodes.filter(
                    (item) => item.databaseId !== attendanceId
                  ),
                };
              }
              return {
                ...existingAttendances,
                edges: existingAttendances.edges.filter(
                  (item) => item.node.databaseId !== attendanceId
                ),
              };
            },
          },
        });
      },
      onCompleted() {
        setMessage({ text: 'Attendance deleted' });
      },
      onError(e) {
        setMessage({ text: e.message, severity: 'error' });
      },
    }
  );

  useEffect(() => {
    document.getElementById('root').scrollTop = 0;
  }, []);

  if (loading || !data) {
    return progress;
  }

  const role = user.roles.nodes.map((item) => item.name)[0];

  const {
    worker: {
      firstName,
      lastName,
      gender,
      pictureUrl,
      phone,
      email,
      smsStatus,
      membershipYear,
      address,
      lga,
      state,
      country,
      branch,
      subBranchId,
      workerGroups,
      attendances,
    },
  } = data;

  const hasAttendance = attendances.edges.find(
    (item) => item.node.eventId === currentEvent.id
  );

  return (
    <React.Fragment>
      {(loadingAddAttendance || loadingDeleteAttendance) && progress}
      {message.text && (
        <ActionAlert
          message={message.text}
          severity={message.severity}
          setMessage={setMessage}
        />
      )}
      <Helmet title="Worker" />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}>
          <Details
            firstName={firstName}
            lastName={lastName}
            gender={gender}
            pictureUrl={pictureUrl}
            branchId={branch.id}
            currentEvent={currentEvent}
            workerGroups={workerGroups}
            hasAttendance={hasAttendance}
            addAttendance={addAttendance}
            deleteAttendance={deleteAttendance}
            loadingAddAttendance={loadingAddAttendance}
            loadingDeleteAttendance={loadingDeleteAttendance}
          />
          <Departments workerGroups={workerGroups} />
          <Contact
            address={address}
            phone={phone}
            smsStatus={smsStatus}
            email={email}
            lga={lga}
            state={state}
            country={country}
            branch={branch}
            subBranchId={subBranchId}
            membershipYear={membershipYear}
          />
        </Grid>
        {role === 'administrator' ? (
          <Grid item xs={12} lg={8} xl={9}>
            <CalendarActivity id={id} />
            <TimelineActivity id={id} progress={progress} />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
      <SendMessage
        open={sendMessage}
        handleClose={() => setSendMessage(false)}
        firstName={firstName}
        lastName={lastName}
      />
      <Fab
        color="primary"
        aria-label="Message"
        onClick={() => navigate(`/workers/edit/${id}`)}
      >
        <Edit />
      </Fab>
    </React.Fragment>
  );
}

export default Worker;
