import React from 'react';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
} from '@mui/material';
import { spacing, keyframes } from '@mui/system';

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${(props) => props.theme.palette.primary.main};
    `}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const LiveContainer = styled.div`
  position: absolute;
  top: 13px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  color: ${(props) => props.theme.palette.success.light};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  font-weight: bold;
`;

const indicatorAnimation = keyframes`
  0% {
    transform: translateX(0%);
    margin-left: 0;
  }
  50% {
    transform: translateX(0%);
    margin-left: 0;
  }
  100% {
    transform: translateX(50%);
    margin-left: -2px;
  }
`;

const liveIndicatorAnimation = keyframes`
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }

`;

const ProgressContainer = styled.div`
  overflow: hidden;
  width: 25px;
`;

const Indicator = styled.div`
  width: 200%;
  line-height: 0;
  animation: ${indicatorAnimation} 1.6s 1s infinite alternate
    cubic-bezier(0.21, 0.85, 0.34, 0.98);
`;

const LiveIndicator = styled.div`
  width: 50%;
  transform-origin: left;
  transform: scaleX(0);
  animation: ${liveIndicatorAnimation} 1.6s 1s infinite alternate
    cubic-bezier(0.87, 0.01, 0.87, 0.5);
  height: 2px;
  float: left;
  background-color: ${(props) => props.theme.palette.success.light};
`;

const Percentage = styled(MuiTypography)`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => rgba(props.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      color: ${(props) => rgba(props.theme.palette.primary.main, 0.85)};
    `}
`;

const IllustrationImage = styled.img`
  height: 100px;
  position: absolute;
  right: ${(props) => props.theme.spacing(1)};
  bottom: ${(props) => props.theme.spacing(1)};
  display: none;

  ${(props) => props.theme.breakpoints.between('xs', 'lg')} {
    display: block;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

const Live = () => {
  return (
    <LiveContainer>
      LIVE
      <ProgressContainer>
        <Indicator>
          <LiveIndicator></LiveIndicator>
        </Indicator>
      </ProgressContainer>
    </LiveContainer>
  );
};

const Stats = ({
  title,
  amount,
  total,
  endTime,
  chip,
  percentagetext,
  percentagecolor,
  illustration,
}) => {
  return (
    <Card illustration={illustration}>
      <CardContent>
        <Typography variant="h6" mb={4}>
          {title}
        </Typography>
        <Typography variant="h3" mb={3}>
          <Box fontWeight="fontWeightRegular">{amount}</Box>
        </Typography>
        <Percentage
          variant="subtitle2"
          color="textSecondary"
          percentagecolor={percentagecolor}
          illustration={illustration}
        >
          <span>{percentagetext}</span> {total}
        </Percentage>
        {!illustration && chip && <Chip label={chip} />}
        {!endTime && <Live />}
      </CardContent>

      {!!illustration && (
        <IllustrationImage src={illustration} alt="Illustration" />
      )}
    </Card>
  );
};

export default Stats;
