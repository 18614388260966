import React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';

import { Box, Paper, Typography } from '@mui/material';

import { ReactComponent as Logo } from '../../vendor/logo.svg';
import SignInComponent from '../../components/auth/SignIn';

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin: auto;
  display: block;
  margin-bottom: 10px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Sign In" />
        <Brand />
        <Box sx={{ marginBottom: '0.5rem' }}>
          <Typography component="h1" variant="h3" align="center" gutterBottom>
            TLC Workers Portal
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Sign in to your account to continue
          </Typography>
        </Box>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
