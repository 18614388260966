import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

import { spacing } from '@mui/system';

import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  ListItemButton,
} from '@mui/material';

import { ReactComponent as Logo } from '../../vendor/logo.svg';
import Footer from './SidebarFooter';
import SidebarNav from './SidebarNav';
import SidebarFilter from './SidebarFilter';

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: flex-start;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;

const Sidebar = ({
  items,
  filter,
  showFooter = true,
  onClose = () => {},
  ...rest
}) => {
  return (
    <Drawer variant="permanent" onClose={onClose} {...rest}>
      <Brand component={filter ? 'div' : NavLink} to="/">
        {filter ? (
          <Box ml={1}>Filter List</Box>
        ) : (
          <>
            <BrandIcon /> <Box ml={1}>TLC Portal</Box>
          </>
        )}
      </Brand>
      {filter ? (
        <SidebarFilter />
      ) : (
        <SidebarNav items={items} onClose={onClose} />
      )}

      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
