import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';

import useAuth from '../../hooks/useAuth';
import ActionAlert from '../components/ActionAlert';

const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const GET_SETTINGS = gql`
  query GetSettings {
    pollInterval
    lineGraphInterval
  }
`;

const UPDATE_SETTINGS = gql`
  mutation updateSettings($pollInterval: Int!, $lineGraphInterval: Int!) {
    updateAppSettings(
      input: {
        clientMutationId: "updateSettings"
        pollInterval: $pollInterval
        lineGraphInterval: $lineGraphInterval
      }
    ) {
      pollInterval
      lineGraphInterval
    }
  }
`;

function Dashboard({ data }) {
  const [state, setState] = useState(data);
  const [message, setMessage] = useState({});
  const [updateSettings, { loading }] = useMutation(UPDATE_SETTINGS, {
    variables: state,
    update(
      cache,
      {
        data: {
          updateAppSettings: { pollInterval, lineGraphInterval },
        },
      }
    ) {
      cache.updateQuery({ query: GET_SETTINGS }, () => ({
        pollInterval,
        lineGraphInterval,
      }));
    },
    onCompleted() {
      setMessage({ text: 'Settings updated' });
    },
    onError(e) {
      setMessage({ text: e.message, severity: 'error' });
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value && parseInt(value),
    }));
  };

  return (
    <>
      {message.text && (
        <ActionAlert
          message={message.text}
          severity={message.severity}
          setMessage={setMessage}
        />
      )}
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom mb={6}>
            Dashboard Settings
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateSettings();
            }}
          >
            <Grid container spacing={6} mb={2}>
              <Grid item md={8}>
                <TextField
                  name="pollInterval"
                  label="Polling Interval"
                  variant="outlined"
                  type="number"
                  value={state.pollInterval}
                  onChange={handleChange}
                  fullWidth
                  my={2}
                />

                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    name="lineGraphInterval"
                    label="Line Graph Mins Interval"
                    variant="outlined"
                    type="number"
                    min="15"
                    step="15"
                    max="60"
                    InputProps={{ inputProps: { min: 15, max: 60, step: 15 } }}
                    value={state.lineGraphInterval}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                JSON.stringify(state) === JSON.stringify(data) || loading
                  ? true
                  : false
              }
            >
              Save changes
            </Button>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

function Settings() {
  const { loading, data } = useQuery(GET_SETTINGS);
  const { progress } = useAuth();

  if (loading || !data) {
    return progress;
  }

  return (
    <React.Fragment>
      <Helmet title="Settings" />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Dashboard data={data} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Settings;
