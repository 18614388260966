import React from 'react';
import { useLocation } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import reduceChildRoutes from './reduceChildRoutes';

const SidebarNavList = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;
  const { user } = useAuth();
  const role = user.roles.nodes.map((item) => item.name)[0];
  let filteredPages = pages;
  if (role === 'contributor') {
    filteredPages = pages.filter((item) => item.title === 'Workers');
  }
  if (role === 'author') {
    filteredPages = pages.filter((item) => item.title === 'Dashboard');
  }

  const childRoutes = filteredPages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    []
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
