import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components/macro';
import { ChevronDown, ChevronUp } from 'react-feather';

import { Button, Typography, Menu, MenuItem } from '@mui/material';

import useFilters from '../../hooks/useFilters';

const DropDownContainer = styled(Typography)`
  ${(props) => props.theme.breakpoints.down('md')} {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const GET_EVENTS = gql`
  query GetEvents {
    events(first: 5) {
      nodes {
        id: databaseId
        name
        startTime
        endTime
        includeAuto
      }
    }
  }
`;

function EventsDropdown() {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const { data } = useQuery(GET_EVENTS);
  const { currentEvent, setCurrentEvent } = useFilters();
  const events = data?.events?.nodes || [];

  useEffect(() => {
    if (events.length) {
      setCurrentEvent(events[0]);
    }
  }, [events]); // eslint-disable-line  react-hooks/exhaustive-deps

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleEvent = (event) => {
    closeMenu();
    setCurrentEvent(event);
  };

  return (
    <DropDownContainer component="div" noWrap>
      <Button
        aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        color="inherit"
        onClick={toggleMenu}
      >
        <Typography
          component="span"
          variant="h5"
          noWrap
          sx={{ textTransform: 'uppercase', color: '#fff' }}
        >
          {currentEvent.name}
        </Typography>
        {Boolean(anchorMenu) ? <ChevronUp /> : <ChevronDown />}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {events.map((eve, index) => (
          <MenuItem onClick={() => handleEvent(eve)} key={index}>
            <span>{eve.name}</span>
          </MenuItem>
        ))}
      </Menu>
    </DropDownContainer>
  );
}

export default EventsDropdown;
