import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { gql, useLazyQuery } from '@apollo/client';

import {
  AutoSizer,
  WindowScroller,
  List,
  InfiniteLoader,
} from 'react-virtualized';

import {
  Avatar as MuiAvatar,
  Badge as MuiBadge,
  Box as MuiBox,
  ListItem,
  ListItemText as MuiListItemText,
  ListItemAvatar as MuiListItemAvatar,
  Skeleton,
  Typography,
} from '@mui/material';

import useFilters from '../../hooks/useFilters';
import useAuth from '../../hooks/useAuth';
import { attendanceGrade, stringToColour } from '../../utils/utils';

const GET_WORKERS = gql`
  query GetWorkers(
    $first: Int! = 40
    $cursor: String
    $branchId: Int! = 0
    $search: String = ""
  ) {
    workers(
      first: $first
      after: $cursor
      where: {
        branchId: $branchId
        orderby: { field: FIRST_NAME, order: ASC }
        search: $search
      }
    ) {
      edges {
        cursor
        node {
          id: databaseId
          firstName
          otherName
          lastName
          gender
          maritalStatus
          address
          lga
          state
          country
          phone
          email
          smsStatus
          pictureUrl
          membershipYear
          subBranchId
          branch {
            id: databaseId
            name
          }
          workerGroups {
            nodes {
              ID: databaseId
              groupId
              groupLevel
              groupLevelId
              groupName
            }
          }
          attendances(first: 5) {
            edges {
              node {
                ID: databaseId
                eventId
                eventName
                eventStarted
                createdAt
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
      }
    }
  }
`;

const ListItemAvatar = styled(MuiListItemAvatar)`
  margin-top: 1px;
  & .color-scale-40 .MuiBadge-badge {
    background-color: #d6e685;
  }
  & .color-scale-60 .MuiBadge-badge {
    background-color: #8cc665;
  }
  & .color-scale-80 .MuiBadge-badge {
    background-color: #44a340;
  }
  & .color-scale-100 .MuiBadge-badge {
    background-color: #1e6823;
  }
`;

const ListItemText = styled(MuiListItemText)`
  margin-left: 14px;
  & .MuiTypography-body1 {
    font-weight: bold;
    font-size: 1.1rem;
  }
`;

const Avatar = styled(MuiAvatar)`
  width: 54px;
  height: 54px;
`;

const Badge = styled(MuiBadge)`
  & .MuiBadge-badge {
    border: 2px solid #fff;
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }
`;

const Box = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BoxSkeleton = styled(Box)`
  padding: 8px 16px;
`;

const SkeletonRectangle = styled(Skeleton)`
  margin-left: 14px;
  height: 52px;
`;

const WorkersListContainer = styled.div`
  margin: 10px 0 30px 0;
  visibility: ${(props) => (props.workersscroll ? 'hidden' : 'visible')};
`;

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 3 }).map((item, index) => (
        <BoxSkeleton key={index}>
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
          <SkeletonRectangle variant="rectangular" width="100%" />
        </BoxSkeleton>
      ))}
    </>
  );
}

function WorkersList({
  edges,
  pageInfo,
  currentEvent,
  currentSearch,
  currentBranch,
  fetchMore,
  setWorkersScroll,
}) {
  const navigate = useNavigate();
  let rows = edges.map((edge) => {
    let workerLevel = edge.node.workerGroups.nodes.reduce((acc, item) => {
      return `${acc ? `${acc} |` : ''} ${item.groupLevel}`;
    }, '');
    const firstWorkerLevel = edge.node.workerGroups.nodes[0]?.groupLevel;

    return {
      ...edge.node,
      branch: edge.node.branch.name,
      workerLevel,
      firstWorkerLevel,
    };
  });

  rows =
    (currentSearch &&
      currentBranch &&
      rows.filter((item) => {
        const firstName = item.firstName.toLowerCase();
        const lastName = item.lastName.toLowerCase();
        const otherName = item.otherName?.toLowerCase();
        const search = currentSearch.toLowerCase();
        if (!isNaN(currentSearch)) {
          return item.phone.includes(currentSearch);
        }
        return (
          firstName.includes(search) ||
          lastName.includes(search) ||
          (otherName && otherName.includes(search))
        );
      })) ||
    rows;

  const rowRenderer = ({ index, key, style }) => {
    const row = rows[index];
    const {
      attendances: { edges },
    } = row;
    const attended = edges.find(
      (item) => item.node.eventId === currentEvent.id
    );
    const colorScale =
      attended &&
      `color-scale-${attendanceGrade(
        currentEvent.startTime,
        attended.createdAt
      )}`;
    const stringColor = stringToColour(`${row.firstName}-${row.lastName}`);
    return (
      <div key={key} style={style}>
        <ListItem
          alignItems="flex-start"
          button
          style={{ height: 72 }}
          onClick={() => {
            setWorkersScroll(document.getElementById('root').scrollTop);
            navigate(`/workers/${row.id}`);
          }}
        >
          <ListItemAvatar>
            {attended ? (
              <Badge
                overlap="circular"
                badgeContent=" "
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
                className={colorScale}
              >
                {row.pictureUrl ? (
                  <Avatar src={row.pictureUrl} />
                ) : (
                  <Avatar
                    style={{
                      fontSize: '1.5rem',
                      color: stringColor.color,
                      background: stringColor.background,
                    }}
                  >
                    {row.firstName.charAt(0)}
                    {row.lastName.charAt(0)}
                  </Avatar>
                )}
              </Badge>
            ) : (
              <>
                {row.pictureUrl ? (
                  <Avatar src={row.pictureUrl} />
                ) : (
                  <Avatar
                    style={{
                      fontSize: '1.5rem',
                      color: stringColor.color,
                      background: stringColor.background,
                    }}
                  >
                    {row.firstName?.charAt(0)}
                    {row.lastName?.charAt(0)}
                  </Avatar>
                )}
              </>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box>
                <Typography noWrap mr={2}>
                  {`${row.firstName} ${row.lastName}`}
                </Typography>
                <Typography variant="body2" color="rgba(0,0,0,0.6)" noWrap>
                  {row.firstWorkerLevel}
                </Typography>
              </Box>
            }
            secondary={row.phone}
          />
        </ListItem>
      </div>
    );
  };

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => !!rows[index]}
      loadMoreRows={() =>
        pageInfo.hasNextPage &&
        fetchMore({
          variables: {
            cursor: pageInfo.endCursor,
          },
        })
      }
      rowCount={pageInfo.total}
    >
      {({ onRowsRendered, registerChild }) => (
        <WindowScroller scrollElement={document.getElementById('root')}>
          {({ height, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  ref={registerChild}
                  autoHeight
                  height={height || window.innerHeight}
                  rowCount={rows.length}
                  rowHeight={72}
                  rowRenderer={rowRenderer}
                  scrollTop={scrollTop}
                  width={width}
                  onRowsRendered={onRowsRendered}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
  );
}

function Workers() {
  const {
    currentEvent,
    currentSearch,
    currentBranch,
    workersScroll,
    setWorkersScroll,
  } = useFilters();
  const [scrollTop, setScrollTop] = useState(0);
  const [getWorkers, { loading, data, fetchMore }] = useLazyQuery(GET_WORKERS);
  const { progress } = useAuth();

  useEffect(() => {
    if (scrollTop) {
      document.getElementById('root').scrollTop = scrollTop;
      setWorkersScroll(0);
    }
  }, [scrollTop]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (workersScroll) {
      setScrollTop(workersScroll);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.getElementById('root').scrollTop = 0;
  }, [currentSearch]);

  useEffect(() => {
    const handler = setTimeout(() => {
      getWorkers({
        variables: {
          branchId: currentBranch ? currentBranch.id : 0,
          ...((currentSearch || currentBranch?.id) && {
            search: currentSearch,
          }),
        },
        notifyOnNetworkStatusChange: true,
      });
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [currentSearch, currentBranch?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!data) {
    return progress;
  }

  const {
    workers: { edges, pageInfo },
  } = data;

  return (
    <React.Fragment>
      <Helmet title="Workers" />

      <WorkersListContainer workersscroll={workersScroll}>
        <WorkersList
          edges={edges}
          pageInfo={pageInfo}
          currentEvent={currentEvent}
          currentSearch={currentSearch}
          currentBranch={currentBranch}
          fetchMore={fetchMore}
          setWorkersScroll={setWorkersScroll}
        />
        {loading && <LoadingSkeleton />}
      </WorkersListContainer>
    </React.Fragment>
  );
}

export default Workers;
