import React from 'react';
import styled, { withTheme } from 'styled-components/macro';
import Chart from 'react-chartjs-2';
import { MoreVertical } from 'react-feather';

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material';
import { spacing } from '@mui/system';
import { alpha } from '@mui/material/styles';

import { tConvert } from '../../../utils/utils';

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

function LineChart({ theme, endTime, workersPageInfo }) {
  const data = (canvas) => {
    const ctx = canvas.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

    let labels = workersPageInfo.lineDataSisters.map((item) =>
      tConvert(item.hour)
    );
    if (
      workersPageInfo.lineDataSisters.length >
      workersPageInfo.lineDataBrothers.length
    ) {
      labels = workersPageInfo.lineDataBrothers.map((item) =>
        tConvert(item.hour)
      );
    }
    const dataBrothers = workersPageInfo.lineDataBrothers.map(
      (item) => item.present
    );
    const dataSisters = workersPageInfo.lineDataSisters.map(
      (item) => item.present
    );

    if (endTime) {
      labels.pop();
      labels = [...labels, tConvert(endTime)];
    }

    return {
      labels,
      datasets: [
        {
          label: 'Sisters',
          fill: true,
          backgroundColor: gradient,
          borderColor: theme.palette.secondary.main,
          tension: 0.4,
          data: dataSisters,
        },
        {
          label: 'Brothers',
          fill: true,
          backgroundColor: 'transparent',
          borderColor: theme.palette.grey[500],
          borderDash: [4, 4],
          tension: 0.4,
          data: dataBrothers,
        },
      ],
    };
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0.0)',
        },
      },
      y: {
        grid: {
          color: 'rgba(0,0,0,0.0375)',
          fontColor: '#fff',
        },
      },
    },
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Aggregate Attendance"
      />
      <CardContent>
        <ChartWrapper>
          <Chart type="line" data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}
export default withTheme(LineChart);
