import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components/macro';

import {
  Autocomplete,
  Button as MuiButton,
  Menu,
  MenuItem,
  InputBase,
  Typography as MuiTypography,
  TextField,
} from '@mui/material';

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search as SearchIcon,
} from '@mui/icons-material';

import useAuth from '../hooks/useAuth';

const Button = styled(MuiButton)`
  background-color: rgba(46, 51, 141, 0.08);
  margin-right: 8px;
  max-width: 300px;
  & svg {
    margin-left: 5px;
  }
  &:hover {
    background-color: rgba(46, 51, 141, 0.12);
  }
`;

const SearchContainer = styled.div`
  border-radius: 4px;
  background-color: rgba(46, 51, 141, 0.08);
  position: relative;
  display: flex;
`;

const SearchIconWrapper = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  margin-right: 5px;
  margin-top: 9px;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.palette.primary.main};
    padding-top: ${(props) => props.theme.spacing(2.0)};
    padding-right: ${(props) => props.theme.spacing(2.0)};
    padding-bottom: ${(props) => props.theme.spacing(2.0)};
    padding-left: ${(props) => props.theme.spacing(2.0)};
    width: 140px;
  }
`;

const Typography = styled(MuiTypography)`
  font-weight: 500;
`;

const GET_BRANCHES = gql`
  query GetBranches {
    branches(first: 0, where: { orderby: { field: NAME, order: ASC } }) {
      nodes {
        ID: databaseId
        name
        typeId
      }
    }
  }
`;

export function Search({ value, onChange, placeholder = 'Search' }) {
  return (
    <SearchContainer>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
    </SearchContainer>
  );
}

export function ButtonDropdown({
  options,
  value,
  name,
  allLabel,
  disableAll = false,
  handleChange = null,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (item) => {
    setAnchorEl(null);
    if (handleChange) {
      return handleChange(item.value);
    }
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const currentLabel =
    options.find((item) => item.value === value)?.label || allLabel;

  return (
    <>
      <Button onClick={handleClick} color="primary">
        <Typography noWrap>{currentLabel}</Typography>
        {Boolean(anchorEl) ? (
          <KeyboardArrowUp fontSize="small" />
        ) : (
          <KeyboardArrowDown fontSize="small" />
        )}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {!disableAll ? (
          <MenuItem onClick={() => handleMenuItemClick({ value: 0 })}>
            {allLabel}
          </MenuItem>
        ) : null}
        {options.map((item, index) => (
          <MenuItem
            key={index}
            selected={item.value === value}
            onClick={() => handleMenuItemClick(item)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export function BranchDropdown({
  label,
  value,
  handleChange,
  required = false,
}) {
  const { loading, data } = useQuery(GET_BRANCHES);
  const { progress } = useAuth();

  if (loading || !data) {
    return progress;
  }

  const {
    branches: { nodes },
  } = data;
  const options = nodes.map((item) => ({
    id: item.ID,
    name: `${item.name} ${[4, 7].includes(item.typeId) ? 'Autonomous' : ''}`,
  }));

  const defaultProps = {
    options,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  return (
    <Autocomplete
      {...defaultProps}
      value={value}
      onChange={(e, val) => {
        handleChange(val);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || 'Select Branch'}
          required={required}
          margin="none"
        />
      )}
    />
  );
}
