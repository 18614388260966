import React, { useState, useEffect, createRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import GoogleMapReact, { Maps } from 'google-map-react';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import 'photoswipe/dist/photoswipe.css';

import {
  Avatar as MuiAvatar,
  Card as MuiCard,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableCell as MuiTableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { spacing } from '@mui/system';
import { tableCellClasses } from '@mui/material/TableCell';

import useAuth from '../../hooks/useAuth';
import useFilters from '../../hooks/useFilters';
import { stringToColour } from '../../utils/utils';
import { Search } from '../../utils/components';

const GET_BRANCH = gql`
  query Branch($id: ID!) {
    branch(id: $id, idType: DATABASE_ID) {
      id: databaseId
      name
      lat
      lng
      typeId
      pastorId
      asstPastorId
      workers(first: 0) {
        nodes {
          id: databaseId
          firstName
          lastName
          phone
          gender
          pictureUrl
          membershipYear
          workerGroups {
            nodes {
              ID: databaseId
              groupId
              groupLevel
              groupLevelId
              groupName
            }
          }
        }
      }
      images {
        src
        thumb
        height
        width
      }
    }
  }
`;

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  width: 54px;
  height: 54px;
  margin-right: 10px;
`;

const CountStat = styled.span`
  color: ${(props) => props.theme.palette.primary.main};
`;

const BranchStat = styled.small`
  font-weight: normal;
  margin-right: 5px;
`;

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;

const HeadAvatar = styled(MuiAvatar)`
  display: inline-block;
  border-radius: 6px;
  font-size: 3rem;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  & span {
    font-weight: 500;
  }
`;

const TableCell = styled(MuiTableCell)`
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'position',
    numeric: false,
    disablePadding: false,
    label: 'Position',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'membershipYear',
    numeric: false,
    disablePadding: false,
    label: 'Member Since',
  },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function BranchHeads({ pastorId, asstPastorId, typeId, workers: { nodes } }) {
  const isMdDown = !useMediaQuery('(min-width:960px)');
  const avatarDimension = isMdDown ? '200px' : '158px';

  const pastor = nodes.find((item) => item.id === pastorId);
  const asstPastor = nodes.find((item) => item.id === asstPastorId);
  const womanLeader = nodes.find((item) =>
    item.workerGroups.nodes.find((ele) => ele.groupLevelId === 20)
  );
  const asstWomanLeader = nodes.find((item) =>
    item.workerGroups.nodes.find((ele) => ele.groupLevelId === 21)
  );
  const heads = [pastor, asstPastor, womanLeader, asstWomanLeader];
  const headsTitle = [
    `${typeId === 5 ? 'Regional' : 'Branch'} Pastor`,
    'Asst. Branch Pastor',
    'Woman Leader',
    'Asst. Woman Leader',
  ];
  return (
    <Grid container spacing={6}>
      {heads.map((item, index) => {
        const stringColor = stringToColour(
          `${item?.firstName}-${item?.lastName}`
        );
        const fullName = item ? `${item?.firstName} ${item?.lastName}` : '-';
        return (
          <Grid item xs={12} md={3} key={index}>
            <Card mb={6}>
              <Centered>
                <CardHeader></CardHeader>
                {item?.pictureUrl ? (
                  <HeadAvatar
                    alt={`${item?.firstName} ${item?.lastName}`}
                    src={item?.pictureUrl}
                    sx={{ width: avatarDimension, height: avatarDimension }}
                  />
                ) : (
                  <HeadAvatar
                    sx={{
                      color: stringColor.color,
                      background: stringColor.background,
                      width: avatarDimension,
                      height: avatarDimension,
                      lineHeight: avatarDimension,
                    }}
                    alt={`${item?.firstName} ${item?.lastName}`}
                  >
                    {item?.firstName.charAt(0)}
                    {item?.lastName.charAt(0)}
                  </HeadAvatar>
                )}
                <Typography variant="h4" noWrap sx={{ padding: '0 5px' }}>
                  {fullName}
                </Typography>
                <Typography variant="body1" component="div" gutterBottom mb={3}>
                  {headsTitle[index]}
                </Typography>
              </Centered>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

function BranchMap({ branchName, lat, lng }) {
  const [streetView, setStreetView] = useState(false);

  const options = {
    center: {
      lat: lat,
      lng: lng,
    },
    zoom: 14,
  };

  const getMapOptions = (maps: Maps) => {
    return {
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: false,
      streetViewControl: true,
    };
  };

  const apiIsLoaded = (map, maps) => {
    if (streetView) {
      const panorama = new maps.StreetViewPanorama(
        ReactDOM.findDOMNode(panoramaRef.current),
        {
          position: {
            lat: lat,
            lng: lng,
          },
          pov: {
            heading: 160,
            pitch: 0,
          },
          visible: true,
        }
      );
      map.setStreetView(panorama);
    } else {
      new maps.Marker({
        position: {
          lat: lat,
          lng: lng,
        },
        map,
        title: branchName,
      });
    }
  };

  const handleChangeStreetView = (event) => {
    setStreetView(event.target.checked);
  };

  const panoramaRef = createRef();

  return (
    <Card mb={1}>
      <CardHeader
        title={'Branch Map'}
        action={
          <FormControlLabel
            control={
              <Switch checked={streetView} onChange={handleChangeStreetView} />
            }
            label="Street View"
          />
        }
        sx={{ paddingBottom: 0 }}
      />
      <CardContent>
        <GoogleMapReactWrapper>
          <GoogleMapReact
            key={streetView}
            ref={panoramaRef}
            options={getMapOptions}
            bootstrapURLKeys={{
              key: 'AIzaSyA-aWrwgr64q4b3TEZwQ0lkHI4lZK-moM4',
            }}
            defaultCenter={options.center}
            defaultZoom={options.zoom}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            yesIWantToUseGoogleMapApiInternals={true}
          />
        </GoogleMapReactWrapper>
      </CardContent>
    </Card>
  );
}

function BranchGallery({ id, images }) {
  const isMdDown = !useMediaQuery('(min-width:960px)');
  const cols = isMdDown ? 2 : 4;

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: `#${id}`,
      children: 'a',
      showHideAnimationType: 'zoom',
      pswpModule: () => import('photoswipe'),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, [id]);

  return (
    <Card mb={1}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Branch Gallery
        </Typography>

        <Spacer mb={6} />

        <ImageList cols={cols} className="pswp-gallery" id={id}>
          {images.map((item, index) => (
            <a
              key={index}
              href={item.src}
              data-pswp-width={item.width}
              data-pswp-height={item.height}
              target="_blank"
              rel="noreferrer"
            >
              <ImageListItem>
                <img
                  src={item.thumb}
                  loading="lazy"
                  alt={`Branch pic ${index + 1}`}
                />
              </ImageListItem>
            </a>
          ))}
        </ImageList>
      </CardContent>
    </Card>
  );
}

function BranchList({ workers }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [dense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const navigate = useNavigate();
  const { pageMeta, setPageMeta } = useFilters();
  const [searchTerm, setSearchTerm] = useState(pageMeta?.branch?.term || '');
  const [page, setPage] = useState(pageMeta?.branch?.page || 0);

  useEffect(() => {
    if (searchTerm) {
      setPage(0);
    }
  }, [searchTerm]);

  useEffect(() => {
    const pageScroll = pageMeta?.branch?.scroll;
    document.getElementById('root').scrollTop = pageScroll;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let rows = workers.nodes.map((item) => {
    return {
      ...item,
      name: `${item.firstName} ${item.lastName}`,
      positions: item.workerGroups.nodes.reduce((acc, item) => {
        return `${acc ? `${acc} -` : ''} ${item.groupLevel}`;
      }, ''),
    };
  });
  if (searchTerm) {
    rows = rows.filter(
      (item) =>
        item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  const brothers = workers.nodes.filter((item) => item.gender === 'M').length;
  const sisters = workers.nodes.filter((item) => item.gender === 'F').length;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Card>
      <CardHeader
        title={
          <>
            <span>Branch Workers </span>
            <br />
            <BranchStat>
              Total:<CountStat>{workers.nodes.length} </CountStat>
              Brothers:<CountStat>{brothers} </CountStat>
              Sisters:<CountStat>{sisters}</CountStat>
            </BranchStat>
          </>
        }
        action={<Search value={searchTerm} onChange={setSearchTerm} />}
      />
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const stringColor = stringToColour(
                    `${row.firstName}-${row.lastName}`
                  );

                  return (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setPageMeta([
                          {
                            name: 'branch',
                            page,
                            term: searchTerm,
                            scroll: document.getElementById('root').scrollTop,
                          },
                        ]);
                        navigate(`/workers/${row.id}`);
                      }}
                    >
                      <TableCell>
                        <Name>
                          {row.pictureUrl ? (
                            <Avatar src={row.pictureUrl} />
                          ) : (
                            <Avatar
                              style={{
                                fontSize: '1.5rem',
                                color: stringColor.color,
                                background: stringColor.background,
                              }}
                            >
                              {row.firstName?.charAt(0)}
                              {row.lastName?.charAt(0)}
                            </Avatar>
                          )}
                          <span>{`${row.firstName} ${row.lastName}`}</span>
                        </Name>
                      </TableCell>
                      <TableCell sx={{ width: '30%' }}>
                        {row.positions}
                      </TableCell>
                      <TableCell>{row.phone}</TableCell>
                      <TableCell>{row.membershipYear}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Card>
  );
}

function Branch() {
  const { id } = useParams();
  const { loading, data } = useQuery(GET_BRANCH, {
    variables: { id },
  });
  const { progress } = useAuth();

  if (loading || !data) {
    return progress;
  }

  const {
    branch: { name, lat, lng, typeId, pastorId, asstPastorId, workers, images },
  } = data;
  const branchName = `${name} ${typeId === 5 ? 'Regional Branch' : 'Branch'}`;

  return (
    <React.Fragment>
      <Helmet title="Branch" />
      <Typography variant="h3" gutterBottom display="inline">
        {branchName}
      </Typography>

      <Grid container spacing={6} mt={1}>
        {id !== '1' && (
          <Grid item xs={12}>
            <BranchHeads
              workers={workers}
              typeId={typeId}
              pastorId={pastorId}
              asstPastorId={asstPastorId}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <BranchMap
            branchName={branchName}
            lat={lat || 40.712784}
            lng={lng || -74.005941}
          />
        </Grid>
        <Grid item xs={12}>
          <BranchGallery id={`branch-${id}`} images={images} />
        </Grid>
        <Grid item xs={12}>
          <BranchList workers={workers} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Branch;
